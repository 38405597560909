<template>
    <LeaveOfAbsence clientCode="EY_LOA"></LeaveOfAbsence>
</template>
<script>
import LeaveOfAbsence from "@/components/shared/LOA/LeaveOfAbsence";
require('@/assets/css/client/EY/ey_variables.scss');

export default {
  components: { LeaveOfAbsence },
  name: "EYLeaveOfAbsence"
};
</script>
<style lang="scss" scoped src="@/assets/css/client/EY/eyloa.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/EY/checklist_styling.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/EY/sample_language.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/EY/bar_chart.scss"></style>
<style lang="scss" scoped src="@/assets/css/shared/feedback/modal_standard.scss"></style>
